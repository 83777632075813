import request from '@/utils/request'

// 查询徽章分组配置列表
export function listBadgeGroup(query) {
  return request({
    url: '/config/badge/group/list',
    method: 'get',
    params: query
  })
}

// 查询徽章分组配置详细
export function getBadgeGroup(id) {
  return request({
    url: '/config/badge/group/' + id,
    method: 'get'
  })
}

// 新增徽章分组配置
export function addBadgeGroup(data) {
  return request({
    url: '/config/badge/group',
    method: 'post',
    data: data
  })
}

// 修改徽章分组配置
export function updateBadgeGroup(data) {
  return request({
    url: '/config/badge/group',
    method: 'put',
    data: data
  })
}

// 删除徽章分组配置
export function delBadgeGroup(id) {
  return request({
    url: '/config/badge/group/' + id,
    method: 'delete'
  })
}
